.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Make the container full height of the viewport */    
}

/* Style for the login box */
.login-box {
    width: 100%;
    max-width: 300px;
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    padding: 20px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

/* Center the input fields and button horizontally */
.login-box button {
    display: block;
    width: 100%;
    margin-bottom: 10px;
}

.TextFieldItem {
    margin-bottom: 15px !important;
    display: block;
    width: 100%;
}